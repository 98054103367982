<template>
	<div class="grid w-full min-h-screen grid-cols-1 md:grid-cols-5">
		<div class="flex flex-col h-screen col-span-1 md:col-span-2 overflow-hidden">
			<div class=" h-full overflow-y-scroll pt-10 sm:pt-20 px-3 sm:px-0 ">
				<div class="max-w-md px-2 mx-auto">
					<img src="../../../assets/images/dscvry_logo-grey.svg" class="h-6"/>
					<Text content="What are some things that describe you?" customClass="mt-8 tracking-tight" size="3xl"
								color="gray-800"/>
				</div>
				<div class="flex flex-col h-full mt-4 px-2 mx-auto max-w-md">
					<Text
							content="A lot of the most powerful things that join us together are things we care about, not just our demographics."
							custom-class="mb-5" color="gray-800"/>
					<MultiSelectDropdown isNotAdd label="Interests and Activities" :options="options"
															 v-model:selected="selected"/>
					<div class="flex flex-wrap gap-2 mt-3">
						<a @click="selected.push(item)" class="px-2 py-1 rounded-full text-xs border border-gray-300"
							 v-for="item in getFiltered()" :key="item">{{ item }}</a>
					</div>
					<Text size="xs" color="primary-600" content="View More" decoration="underline"
								custom-class="cursor-pointer mt-4 self-start"/>
				</div>
			</div>
			<div class="flex-none">
				<div>
					<div class="fixed bottom-0 xs:relative sm:relative w-full py-3 border-t bg-gray-50 px-3 sm:px-0 ">
						<div class="flex justify-end max-w-md px-2 mx-auto">
							<Button :content="'Go to DSCVRY'" variant="primary" :onClick="() => $emit('goNext', selected)" :is-loading="isLoading"/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="hidden h-full col-span-1 md:col-span-3 md:block">
			<Image :src="image.src" :alt="image.alt" width="full" height="screen" customClass="object-cover"/>
		</div>
	</div>
</template>

<script>
/**
 - Combobox with text input to filter onh    basis of name property on default
 - give filter key to provide filter on a custom property
 **/
import Text from '../../atoms/Text/Text.vue';
import Image from '../../atoms/Image/Image.vue';
import Button from '../../atoms/Button/Button.vue';
import MultiSelectDropdown from "../../molecules/Dropdown/MultiSelectDropdown/MultiSelectDropdown";

export default {
	components: {
		Text,
		Image,
		Button,
		MultiSelectDropdown
	},
	props: {
		image: {
			type: Object,
			default: () => {
			}
		},
		items: {
			type: Array,
			default: () => []
		},
		button: {
			type: Object,
			default: () => {
			}
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		options: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			selected: []
		}
	},
	methods: {
		getFiltered() {
			return this.options.filter(o => !this.selected?.find(s => s === o))
		}
	},
};
</script>
