<template>
  <Template
			v-bind="params"
			:options="getOptions()"
			@goNext="(items) => onNext(items)"
			:is-loading="isLoading"
	/>
</template>

<script>
import Template from '../components/templates/InterestAndActivity/InterestAndActivity'
import {c} from '../components/constants'
export default {
	components: {
		Template
	},
	data() {
		return {
			params: c.interests,
			isLoading: false
		}
	},
	methods: {
		getOptions() {
			const options = [];
			this.state.tag.tags.map(t => options.push(t.name));
			return options
		},
		async onNext (items) {
			try {
				this.isLoading = true;
				const tags = []
				items.map(item => tags.push({id: this.state.tag.tags.find(t => t.name === item).id}));
				await this.actions.user.saveUser({
					where: {id: this.state.currentUser?.id},
					data: {
						tags: {
							connect: tags,
						}
					}
				})
				console.log(this.state.currentUser, 'user');
				this.actions.alert.showSuccess({message: 'Saved your interests and activities successfully!'});
				this.$router.push({name: 'Dashboard', query: {registered: true}})
			} catch (e) {
				console.log(e)
			} finally {
				this.isLoading = false;
			}
		}
	},
	async created() {
		await this.actions.tag.getTotalTags({where: {type: 'INTEREST_ACTIVITY'}});
		await this.actions.tag.getTags({where: {type: 'INTEREST_ACTIVITY'}})
		console.log(this.state.tag.totalRecords, this.state.tag.tags)
	},
}
</script>
